import React from 'react';

interface TrustIndicatorsProps {
  className?: string;
}

export function TrustIndicators({ className }: TrustIndicatorsProps) {
  const indicators = [
    { text: '4.5+★ on Trustpilot', link: 'https://www.trustpilot.com/review/snap2pass.com' },
    '100,000+ Happy Customers',
    { text: 'Featured in Product Hunt', link: 'https://www.producthunt.com/posts/snap2pass' },
    '24/7 Customer Support'
  ];

  return (
    <div className={className}>
      <h2 className="text-2xl sm:text-3xl font-bold text-center mb-6 sm:mb-8 bg-clip-text text-transparent bg-gradient-to-r from-indigo-600 to-blue-600">
        Trusted by Thousands
      </h2>
      <div className="flex flex-wrap justify-center gap-4 sm:gap-8">
        {indicators.map((indicator) => (
          <div key={typeof indicator === 'string' ? indicator : indicator.text} className="text-gray-600 font-semibold text-sm sm:text-lg hover:text-indigo-600 transition-colors duration-200">
            {typeof indicator === 'string' ? (
              indicator
            ) : (
              <a href={indicator.link} target="_blank" rel="noopener noreferrer">
                {indicator.text}
              </a>
            )}
          </div>
        ))}
      </div>
    </div>
  );
}
