import { Card, CardContent, CardHeader, CardTitle } from '@/components/ui/card';
import { Clock, Camera, ThumbsUp } from 'lucide-react';
import * as React from 'react';

interface FeatureCardsProps {
  className?: string;
}

const features = [
  { icon: Clock, title: 'Quick & Easy', description: 'Get your passport photo in just 3 minutes, right from your smartphone' },
  { icon: Camera, title: 'AI-Powered', description: 'Our advanced AI ensures your photo meets all official requirements' },
  { icon: ThumbsUp, title: 'Guaranteed Acceptance', description: 'If your photo is rejected, we will refund your money' }
];

export function FeatureCards({ className }: FeatureCardsProps) {
  return (
    <div className={`mt-16 sm:mt-20 grid grid-cols-1 md:grid-cols-3 gap-6 sm:gap-8 ${className}`}>
      {features.map((feature, index) => (
        <Card key={index} className="bg-white border-none shadow-lg hover:shadow-xl transition-shadow duration-300">
          <CardHeader>
            <CardTitle className="flex items-center text-lg sm:text-xl font-bold">
              <feature.icon className="mr-3 h-6 w-6 sm:h-8 sm:w-8 text-indigo-500" />
              <span className="bg-clip-text text-transparent bg-gradient-to-r from-indigo-600 to-blue-600">{feature.title}</span>
            </CardTitle>
          </CardHeader>
          <CardContent>
            <p className="text-sm sm:text-base text-gray-600">{feature.description}</p>
          </CardContent>
        </Card>
      ))}
    </div>
  );
}