import Image from 'next/image';
import Distance from '../../public/Distance.webp';
import DoubleCheck from '../../public/DoubleCheck.svg';

import { Container } from '@/components/Container';
import StructuredData from './StructuredData';

const steps = [
  {
    img: '/HowToStep_1.webp',
    name: 'Body-camera distance',
    description:
      'When taking your photo, it is recommended to make sure that the distance between you and the camera or smartphone is between 50 cm and 2 meters. This will ensure that the photo is clear and of high quality. To achieve this distance, you can ask someone to help you or use a tripod to hold the camera or smartphone. Alternatively, you can take a selfie, but make sure that your head, neck, and the upper part of your shoulders are visible in the picture.',
  },
  {
    img: '/HowToStep2.webp',
    name: 'Posture: straight ahead',
    description:
      'It is important to position your face in the center of the image and to keep your head straight, not tilted in any direction. The entire face, including you hair must be visible in the photo. You should look directly at the camera with your mouth closed and eyes open. A neutral facial expression is required, meaning no smiling or making any kind of face. This will ensure that your photo is a clear representation of your appearance, meeting all the requirements.',
  },
  {
    img: '/HowToStep3.webp',
    name: 'Lighting on both sides of the face',
    description:
      'To ensure the best quality passport photo, it is recommended to take the photo in natural light. The best time to take the photo is during a bright day, this will ensure that your face is evenly illuminated on both sides. Your photo should not have any shadows and all your features such as eyes, nose, mouth, and ears should be clearly visible. This will make the photo suitable for the requirements.',
  },
];
const howto = {
  '@context': 'https://schema.org',
  '@type': 'HowTo',
  name: 'How to take a photo?',
  description: 'Learn how to take a photo with your smartphone.',
  image: 'https://snap2pass.com/iphone_600px.webp',
  step: [
    {
      '@type': 'HowToStep',
      name: 'Body-camera distance',
      text: steps[0].description,
      image: 'https://snap2pass.com/Distance.webp',
      url: 'https://snap2pass.com#step1',
    },
    {
      '@type': 'HowToStep',
      name: 'Posture: Straight ahead',
      text: steps[1].description,
      image: 'https://snap2pass.com/Distance.webp',
      url: 'https://snap2pass.com#step1',
    },
    {
      '@type': 'HowToStep',
      name: 'Even lighting on both sides of the face',
      text: steps[2].description,
      image: 'https://snap2pass.com/Distance.webp',
      url: 'https://snap2pass.com#step1',
    },
  ],
  supply: [
    { '@type': 'HowToSupply', name: 'Smartphone battery' },
    { '@type': 'HowToSupply', name: 'Smartphone disk space' },
  ],
  tool: [{ '@type': 'HowToTool', name: 'Smartphone' }],
  totalTime: 'PT5M',
};

export function HowtoTake() {
  return (
    <section
      id="how_to_take"
      aria-label="Follow these simple instruction to take the perfect ID photo."
      className="relative overflow-hidden py-16"
    >
      <StructuredData data={howto} key="article" />
      <Container className="relative">
        <div className="mx-auto mb-12 text-center">
          <h2 className="text-3xl sm:text-4xl font-extrabold text-gray-900 leading-tight mb-4">
            How to take <span className="bg-clip-text text-transparent bg-gradient-to-r from-indigo-600 to-blue-600">a photo</span>?
          </h2>
          <p className="text-lg text-gray-600">
            Follow these simple instructions to take the perfect ID photo.
          </p>
        </div>
        <div className="mx-auto grid grid-cols-1 grid-flow-row gap-8 lg:grid-cols-3 justify-center bg-slate-100 p-10 rounded-2xl">
          {steps.map((step, index) => (
            <div key={index} className="mx-auto" id={`step${index + 1}`}>
              <div className="relative mx-auto max-w-fit mt-2">
                <DoubleCheck className="absolute -top-[7%] right-[2%] h-[50px] w-[50px]" />
                <Image
                  className="rounded-3xl w-[312px]"
                  src={Distance}
                  alt={
                    'right distance for taking a photo for the perfect passport photo'
                  }
                />
              </div>
              <p className="mx-auto max-w-xs text-center text-xl mt-7 px-4 flex flex-row items-center">
                <span className="text-indigo-600 mr-3 font-bold">{`0${index + 1}`}</span>
                <span className="text-left font-semibold">{step.name}</span>
              </p>
              <p className="mx-auto max-w-sm lg:max-w-xs text-gray-600 text-sm mt-4">
                {step.description}
              </p>
            </div>
          ))}
        </div>
      </Container>
    </section>
  );
}
