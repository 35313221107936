import { Container } from '@/components/Container';
import { useEffect } from 'react';

export function Testimonials() {
  useEffect(() => {
    // Check if the script is already in the head of the dom
    let script = document.querySelector<HTMLScriptElement>(
      `script[src="https://static.senja.io/dist/platform.js"]`,
    );

    if (script) return;

    script = document.createElement('script');
    script.src = 'https://static.senja.io/dist/platform.js';
    script.async = true;
    script.type = 'text/javascript';
    document.body.append(script);
  }, []);

  return (
    <section id="testimonials" aria-label="Customer Testimonials" className="relative overflow-hidden py-16">
      <Container className="relative">
        <div className="mx-auto mb-12 text-center">
          <h2 className="text-3xl sm:text-4xl font-extrabold text-gray-900 leading-tight mb-4">
            What Our <span className="bg-clip-text text-transparent bg-gradient-to-r from-indigo-600 to-blue-600">Customers</span> Say
          </h2>
          <p className="text-lg text-gray-600 mb-8">
            Read genuine feedback from our satisfied users about their experience with our product.
          </p>
        </div>
        <div className="space-y-8 sm:space-y-12">
          <article className="relative py-8 sm:py-10 rounded-lg bg-slate-100 p-4 sm:p-10">
            <div
              className="relative mx-auto senja-embed"
              data-lazyload="true"
              data-id="01110771-c57c-4d42-855e-6e642ea3aadd"
            />
          </article>
          {/* Add more testimonial articles as needed */}
        </div>
      </Container>
    </section>
  );
}
