import { Disclosure } from '@headlessui/react';
import { MinusSmallIcon, PlusSmallIcon } from '@heroicons/react/24/outline';

import { Container } from '@/components/Container';
import ReactMarkdown from 'react-markdown';
import StructuredData from './StructuredData';

const faqs = [
  {
    question: 'What is Snap2Pass?',
    answer:
      'Snap2Pass is an online SaaS service that helps customers create passport and visa photos at home. Customers can take a photo with their smartphone, and Snap2Pass removes the background, resizes the photo, and crops the photo to meet the official document requirements. The service provides a fast and convenient way for customers to get the perfect passport or visa photo without leaving their home.',
  },
  {
    question: 'How does Snap2Pass work?',
    answer:
      'To use Snap2Pass, simply upload a photo taken with your smartphone. The service will automatically remove the background, resize the photo, and crop the photo to meet the official document requirements. Customers can retry as many times as needed to get the perfect photo. The processing time is less than 5 seconds.',
  },
  {
    question: 'What formats can I receive my passport or visa photo in?',
    answer:
      'We provide your passport or visa photo in a high-resolution digital format (JPEG/JPG) suitable for both online applications and printing.',
  },
  {
    question: 'What is the Expert Review Service?',
    answer:
      'Our Expert Review Service is an integral part of the Snap2Pass service, offered at no extra cost. It involves a combination of advanced AI algorithms and human expertise to ensure your photo is absolutely valid for the passport or visa. If our expert service identifies any issues, you are allowed to fix and resend a new photo at no additional cost. We typically review and return your photo within 30 minutes. This additional layer of review ensures that your photo meets all the official requirements and boosts your confidence in the acceptance of the photo by the official agency.',
  },
  {
    question: 'When will I receive my photos and the expert review results?',
    answer:
      'You will receive your photos immediately after they are processed, which typically takes less than 5 seconds. Our Expert Review Service will then evaluate your photo, and you can expect the results typically within 30 minutes.',
  },
  {
    question:
      'What happens if the Expert Review Service identifies a problem with my photo?',
    answer:
      'If our Expert Review Service identifies any issues with your photo, you will be notified and allowed to fix and resend a new photo at no additional cost. This ensures your photo meets all the official requirements.',
  },
  {
    question:
      'Are the passport and visa photos created with Snap2Pass accepted by official agencies?',
    answer:
      'Yes! Snap2Pass provides a money-back guarantee if the photo is not accepted by the official agency. The service has been designed to meet all official requirements for passport and visa photos.',
  },
  {
    question:
      'How long does it take to get my passport or visa photo with Snap2Pass?',
    answer:
      'The processing time for a passport or visa photo with Snap2Pass is less than 5 seconds.',
  },
  {
    question: 'Can I try for free?',
    answer:
      'Yes, you can preview your photo for free! Try it now and pay only when you are satisfied.',
  },
  {
    question: 'Can I retry as many times as I need to get the perfect photo?',
    answer:
      'Yes, customers can retry as many times as they need to get the perfect photo.',
  },
  {
    question: 'Do you provide photo prints?',
    answer:
      'No, however if you are in the US you can follow [our guide](/post/printing-your-passport-photos-amazon-photo-vs-walgreens) to get your prints easily after using our service.',
  },
  {
    question: 'Is it safe to use Snap2Pass?',
    answer:
      'Yes, Snap2Pass is a secure and trusted service. Customer data and information is protected with the latest security measures.',
  },
  {
    question: 'How do I pay for Snap2Pass services?',
    answer:
      'We use Stripe as our payment processing platform, which accepts various payment methods, including credit/debit cards. All payments are secure and encrypted for your safety.',
  },
  {
    question:
      'Is there a money-back guarantee if my passport or visa photo is not accepted by the official agency?',
    answer:
      'Yes, Snap2Pass provides a money-back guarantee if the photo is not accepted by the official agency.',
  },
];

export function Faqs() {
  const faqsData = () => {
    const data = {
      '@context': 'https://schema.org',
      '@type': 'FAQPage',
      mainEntity: faqs.map((faq) => ({
        '@type': 'Question',
        name: faq.question,
        acceptedAnswer: { '@type': 'Answer', text: faq.answer },
      })),
    };
    return data;
  };

  return (
    <section
      id="faq"
      aria-labelledby="faq-title"
      className="relative overflow-hidden bg-slate-50 py-16 sm:py-24"
    >
      <StructuredData data={faqsData()} key="faq" />
      <Container className="relative">
        <div className="mx-auto mb-12 text-center">
          <h2 className="text-3xl sm:text-4xl font-extrabold text-gray-900 leading-tight mb-4">
            Frequently asked <span className="bg-clip-text text-transparent bg-gradient-to-r from-indigo-600 to-blue-600">questions</span>
          </h2>
          <p className="text-lg text-gray-600">
            Can&apos;t find the answer you&apos;re looking for? Reach out to us at{' '}
            <span className="font-semibold">info@snap2pass.com</span>
          </p>
        </div>
        <dl className="mt-10 space-y-6 divide-y divide-gray-200">
          {faqs.map((faq) => (
            <Disclosure as="div" key={faq.question} className="pt-6">
              {({ open }) => (
                <>
                  <dt>
                    <Disclosure.Button className="flex w-full items-start justify-between text-left text-gray-900">
                      <span className="text-lg font-semibold leading-7">
                        {faq.question}
                      </span>
                      <span className="ml-6 flex h-7 items-center">
                        {open ? (
                          <MinusSmallIcon
                            className="h-6 w-6 text-indigo-600"
                            aria-hidden="true"
                          />
                        ) : (
                          <PlusSmallIcon
                            className="h-6 w-6 text-indigo-600"
                            aria-hidden="true"
                          />
                        )}
                      </span>
                    </Disclosure.Button>
                  </dt>
                  <Disclosure.Panel as="dd" className="mt-4 pr-12">
                    <p className="text-base leading-7 text-gray-600">
                      <ReactMarkdown>
                        {faq.answer}
                      </ReactMarkdown>
                    </p>
                  </Disclosure.Panel>
                </>
              )}
            </Disclosure>
          ))}
        </dl>
      </Container>
    </section>
  );
}
